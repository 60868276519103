export const TAB = 'Tab';
export const ENTER = 'Enter';
export const SHIFT = 'Shift';
export const CTRL = 'Control';
export const ALT = 'Alt';
export const ALT_GR = 'AltGraph';
// export const SHIFTLOCK = 'ShiftLock';
export const ERASE = 'Backspace';
export const BACKSPACE = 'Backspace';
export const DEL = 'Delete';
export const ESCAPE = 'Escape';
export const SPACE = 'Space';
export const PAUSE = 'Pause';
export const END = 'End';
export const START = 'Home';
export const HOME = 'Home';
export const PAGE_UP = 'PageUp';
export const PAGE_DOWN = 'PageDown';
export const ARROW_LEFT = 'ArrowLeft';
export const ARROW_UP = 'ArrowUp';
export const ARROW_RIGHT = 'ArrowRight';
export const ARROW_DOWN = 'ArrowDown';
export const F1 = 'F1';
export const F2 = 'F2';
export const F3 = 'F3';
export const F4 = 'F4';
export const F5 = 'F5';
export const F6 = 'F6';
export const F7 = 'F7';
export const F8 = 'F8';
export const F9 = 'F9';
export const F10 = 'F10';
export const F11 = 'F11';
export const F12 = 'F12';
