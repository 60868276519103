import React from 'react';
import FileInput, { IMAGE, PDF } from './FileInput';
import { Button, FormField, Icon, Label } from 'semantic-ui-react';
import { autoDownload } from '../functions/file';
import { makeClassName } from '../functions/ui';

const IconAddFile = () => (
	<Icon.Group>
		<Icon name="file" />
		<Icon corner name="plus" />
	</Icon.Group>
);

const AttachmentField = ({
	name = 'attachment',
	isIcon = false,
	error,
	handleError,
	handleChange,
	attachment,
	loading,
	...props
}) => {
	const handleErrorBuffer = err => handleError(name, err);

	const handleFiles = files => {
		if (error) {
			handleError(name, null);
		}
		handleChange(null, { name, value: files[0] });
	};

	const handleDownload = e => {
		e.preventDefault();
		e.stopPropagation();
		autoDownload(attachment, attachment.name);
	};

	return (
		<FormField className="inline-flex align-items-center m-0">
			<FileInput
				name={name}
				labelProps={{
					className: attachment ? 'ui button tiny labeled right mb-0' : 'mb-0',
					children: (
						<>
							<Button
								as="div"
								type="button"
								basic
								size="tiny"
								className={makeClassName(
									error ? 'red' : 'primary',
									isIcon && 'icon'
								)}
								title={
									isIcon ? 'Déposer ou glisser une pièce' : undefined
								}
								loading={loading}
							>
								<IconAddFile />{' '}
								{!isIcon && 'Déposer ou glisser une pièce'}
							</Button>
							{!isIcon && !!attachment?.name && (
								<Label
									onClick={handleDownload}
									className={error ? 'red' : 'primary'}
									content={attachment.name}
								/>
							)}
						</>
					),
				}}
				nbMax={1}
				maxMo={5}
				types={[PDF, IMAGE]}
				{...props}
				handleFiles={handleFiles}
				handleError={handleErrorBuffer}
				disabled={loading}
			/>
			{!!error && (
				<Label prompt pointing="left" role="alert">
					{error}
				</Label>
			)}
		</FormField>
	);
};

export default AttachmentField;
