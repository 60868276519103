import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	addPortal,
	getPortalIndexById,
	removePortal,
} from '../../reducers/windowReducer';

const useMinimizeModal = ({ id, handleClose, handleOpen, subscribe }) => {
	const dispatch = useDispatch();
	const index = useSelector(state => getPortalIndexById(state, id));
	const [minimized, setMinimized] = useState(false);
	// suivi de l'animation lors de la réduction de la modal
	const [tabAnimated, setTabAnimated] = useState(false);

	const onClose = e => {
		handleClose(e);
		if (minimized) unminimize();
	};

	const onOpen = e => {
		handleOpen(e);
		if (minimized) unminimize();
	};

	const minimize = () => {
		setMinimized(true);
		// permet au parent de déclencher d'autres évènements quand la modal est réduite
		subscribe?.(true);
		// stock l'id de la modal dans le store pour ordonner les modals réduites
		dispatch(addPortal(id));
		// ajoute une transition après un temps, pour qu'elle s'active au bon timing par rapport au state
		setTimeout(() => {
			setTabAnimated(true);
		}, 300);
	};

	const unminimize = () => {
		setMinimized(false);
		// permet au parent de déclencher d'autres évènements quand la modal est réagrandie
		subscribe?.(false);
		// retire l'id de la modal dans le store pour réordonner les modals réduites restantes
		dispatch(removePortal(id));
		// enlève la transition dès que possible lors du réagrandissement
		setTabAnimated(false);
	};

	const handleMinimize = e => {
		e.preventDefault();
		e.stopPropagation();

		if (minimized) {
			unminimize();
		} else {
			minimize();
		}
	};

	const dataProps = useMemo(() => {
		// permet au CSS d'ordonner proprement les modals réduites, voir @mixin modal-minimized-width dans _modal.scss
		return {
			'data-portal-index': index,
			'data-left': (index % 4) * 25,
			'data-bottom': Math.floor(index / 4) * 4.64,
		};
	}, [index]);

	return { minimized, tabAnimated, onClose, onOpen, handleMinimize, dataProps };
};

export default useMinimizeModal;
