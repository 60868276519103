import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css';
import './styles/index.scss';
import App from './App';
import store from './store';
import PageError from './pages/PageError';
import ErrorBoundary from './ErrorBoundary';

const root = createRoot(document.getElementById('root'));
// <React.StrictMode>
root.render(
	<Provider store={store}>
		<Router>
			<ErrorBoundary Component={PageError}>
				<App />
			</ErrorBoundary>
		</Router>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
