// modules
import React from 'react';
import { Button } from 'semantic-ui-react';
import PageLayout from '../layout/PageLayout';
import useGoBack from '../hooks/useGoBack';

const PageError = ({ clearError }) => {
	const goBack = useGoBack();

	const handleGoBack = () => {
		clearError();
		goBack();
	};

	return (
		<PageLayout title="Une erreur est survenue" goBack={false}>
			<p>
				Nous sommes désolé pour le désagrément, une erreur est venue corrompre
				l'interface.
				<br /> Nous faisons notre maximum pour la corriger au plus vite.
			</p>
			<Button
				color="blue"
				onClick={handleGoBack}
				icon="left arrow"
				content="Revenir en lieu sûr"
			/>
		</PageLayout>
	);
};
export default PageError;
