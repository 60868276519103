
export const strictParseFloat = str => parseFloat(str) || 0;

export const nullablePrice = price => (!price ? null : formatPrices(price));

export const roundFloat = (number, decimal = 2) => {
	if (!number || isNaN(number)) return number;
	if (typeof number !== 'number') number = parseFloat(number);
	const decimalRatio = Math.pow(10, decimal);
	return Math.round(number * decimalRatio) / decimalRatio;
};

export const calcPercentOf = (value, total) => roundFloat((value * 100) / (total || 1));

/**
 * @param {number} nb
 * @param {boolean} decimal
 * @returns {string}
 */
export function formatPrices(price, decimal = true) {
	const nbDigit = decimal ? 2 : 0;
	// prevent from -0,00 for very small negative values
	if (price > -0.005 && price < 0.005) price = 0;
	price = new Intl.NumberFormat('fr-FR', {
		minimumFractionDigits: nbDigit,
		maximumFractionDigits: nbDigit,
	}).format(price);
	// firefox blankspace bug
	price = price.replace(/\s+/g, '\u00A0');
	return price + '\u00A0€';
}

/**
 * @param {number} nb
 * @param {number} minDigit
 * @param {number} maxDigit
 * @returns {string}
 */
export function formatPercent(percent, minDigit = 0, maxDigit = 2) {
	// minDigit is only applicable on floats for percents
	if (!Number.isInteger(percent)) {
		// prevent from -0,00 for very small negative values
		if (percent > -0.005 && percent < 0.005) percent = 0;
		percent = new Intl.NumberFormat('fr-FR', {
			minimumFractionDigits: minDigit,
			maximumFractionDigits: maxDigit,
		}).format(percent);
		// firefox blankspace bug
		percent = percent.replace(/\s+/g, '\u00A0');
	}
	return percent + '\u00A0%';
}