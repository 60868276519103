/**
 * @argument {args} classList of [string, array, false, null, undefined]
 * @returns {string}
 */
export const makeClassName = (...classList) =>
	classList.reduce((classList, className) => {
		if (!className) return classList;
		if (Array.isArray(className)) className = makeClassName(className);
		if (!classList) return className;
		return classList + ' ' + className;
	}, '');
// faster than classList.flat().filter(c => c).join(' ')

export function scrollToTopAnimate() {
	window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
}

export function colorPrices(price, onlyNegative = false) {
	if (price > 0 && onlyNegative == false) {
		return 'text-green';
	}
	if (price < 0) {
		return 'text-red';
	}
	return undefined;
}
