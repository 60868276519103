import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
// hooks
import useLoggedFetch from './useLoggedFetch';
import useLocalStorage from '../session/useLocalStorage';
import { setUserLoading, updateUser } from '../../reducers/userReducer';
import { defaultObject } from '../../constants';

function useTokenManager({
	redirection = '/',
	escapeRegex = /^\/(token).*$/,
	params,
	loadingIcon,
} = defaultObject) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const user = useSelector(state => state.user);
	const { getStoredItem } = useLocalStorage();
	const [loading, setLoading] = useState(true);

	const handleResult = result => {
		if (result) {
			if (result.success) {
				const storedUser = getStoredItem(process.env.REACT_APP_LOCAL_KEY);
				dispatch(updateUser(storedUser));
			}
			setLoading(false);
		}
	};
	const [load] = useLoggedFetch({ handleResult, loadingIcon });

	// Get User from local storage
	useEffect(() => {
		if (!user.token) {
			const storedUser = getStoredItem(process.env.REACT_APP_LOCAL_KEY);
			if (!escapeRegex.test(pathname) && storedUser?.id) {
				load({
					url: process.env.REACT_APP_TEST_ENDPOINT,
					token: storedUser.token,
					...params,
				});
			} else {
				setLoading(false);
				if (redirection) {
					navigate(redirection, { replace: true });
				}
			}
		} else {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		if (loading != user.loading) {
			dispatch(setUserLoading(loading));
		}
	}, [loading]);
}

export default useTokenManager;
