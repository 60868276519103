import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

export const messagesSlice = createSlice({
	name: 'messages',
	initialState: {},
	reducers: {
		addMessage: (state, { payload: message }) => {
			for (let iteration of Object.values(state)) {
				if (iteration.value === message.value) return;
			}
			if (message.success) message.type = 'success';
			if (message.error) message.type = 'error';
			if (message.warning) message.type = 'warning';
			state[uuid()] = message;
		},
		removeMessage: (state, { payload: uuid }) => {
			delete state[uuid];
		},
		clearMessages: () => [],
	},
});

export const { addMessage, removeMessage, clearMessages } = messagesSlice.actions;
export default messagesSlice.reducer;
