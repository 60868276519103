// modules
import React from 'react';
import { Button } from 'semantic-ui-react';
// components
import PageLayout from '../layout/PageLayout';
import useGoBack from '../hooks/useGoBack';

const Page403 = ({
	message = "Vous n'avez pas les droits d'accès à ce module. Vous pouvez les demander à la personne propriétaire de cette entreprise.",
}) => {
	const goBack = useGoBack();

	return (
		<PageLayout title="Permission manquante">
			<p>{message}</p>
			<Button
				color="orange"
				onClick={() => goBack()}
				icon="left arrow"
				content="Revenir en arrière"
			/>
		</PageLayout>
	);
};
export default Page403;
