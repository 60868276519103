export const ALL_FILES =
	'application/pdf, image/gif, image/jpeg, image/png, .xlsx, .xls, application/vnd.ms-excel, .csv, .tsv, .xml, .ods, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .odt, .ppt, .pptx, .odp, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain, .rtf';
export const PDF_FILE = 'application/pdf';
export const IMAGE_FILE = 'image/gif, image/jpeg, image/png'; // , image/webp;
export const PDF_AND_IMAGE_FILE = `${PDF_FILE}, ${IMAGE_FILE}`;

export const ACCEPT_TO_LABEL = {
	[ALL_FILES]: 'tous fichiers',
	[PDF_FILE]: 'pdf',
	[IMAGE_FILE]: `png\u00A0; jpg\u00A0; jpeg\u00A0; gif.` /* webp&nbsp;; */,
	[PDF_AND_IMAGE_FILE]: `${PDF_FILE}\u00A0; ${IMAGE_FILE}`,
};
