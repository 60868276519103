import { createSlice } from '@reduxjs/toolkit';

export const darkModeSlice = createSlice({
	name: 'darkMode',
	initialState: null,
	reducers: {
		setDarkModeAction: (state, { payload: darkMode }) => darkMode,
	},
});

export const { setDarkModeAction } = darkModeSlice.actions;
export default darkModeSlice.reducer;
