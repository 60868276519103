// modules
import React from 'react';
import { Container, Header } from 'semantic-ui-react';
import GoBackButton from '../buttons/GoBackButton';
import MenuAccounting from '../menus/MenuAccounting';
import ButtonPrint from '../buttons/ButtonPrint';
const PageLayout = ({
	title,
	goBack = true,
	className,
	children,
	withMenu = false,
	handlePrint,
	loadingPrint,
}) => {
	return (
		<>
			<Container className={className}>
				<div className="flex justify-content-between align-items-center">
					<Header className="flex mb-1" as="h1">
						{goBack && (
							<div>
								<GoBackButton />
							</div>
						)}
						{title}
					</Header>
					{!!handlePrint && (
						<ButtonPrint
							onClick={handlePrint}
							loading={loadingPrint}
							animated={false}
							primary
						/>
					)}
				</div>
				{withMenu && <MenuAccounting />}
				{children}
			</Container>
		</>
	);
};
export default PageLayout;
