import React from 'react';
import { useDispatch } from 'react-redux';
import { setBodySplit } from '../reducers/windowReducer';
import { Button } from 'semantic-ui-react';

const WindowSlider = () => {
	const dispatch = useDispatch();

	return (
		<div className="window-slider">
			<Button
				className="close-window-slider"
				onClick={() => dispatch(setBodySplit(false))}
				icon="window restore"
				size="large"
			/>
		</div>
	);
};

export default WindowSlider;
