// module
import React from 'react';
import { Table } from 'semantic-ui-react';
import { ASC } from '../constants';
import { makeClassName } from '../functions/ui';

/**
 * @prop {array} cols
 * @prop {jsx} children
 * @prop {array|undefined} sortable
 * @prop {function|undefined} handleSort
 * @prop {string|undefined} orderDirection
 * @prop {string|undefined} multiple
 */
export const TableLayout = ({
	cols,
	sortedCol,
	handleSort,
	children,
	orderDirection = ASC,
	multiple = false,
	stickyRows = false,
	transition = false,
	...props
}) => (
	<div className={!stickyRows ? 'table-responsive-wrap' : 'mb-1'}>
		<Table {...props}>
			<Table.Header>
				<Table.Row className={stickyRows ? 'tr-sticky' : undefined}>
					{cols.map(col => {
						if (!col) return null;
						const { sortable, key, nullLast, className, ...colProps } = col;
						if (!sortable) {
							return (
								<Table.HeaderCell
									key={key}
									className={className}
									{...colProps}
								/>
							);
						}
						return (
							<Table.HeaderCell
								key={key}
								className={makeClassName('sortable', className)}
								sorted={sortedCol === key ? orderDirection : undefined}
								onClick={e => handleSort(key, undefined, nullLast)}
								{...colProps}
							/>
						);
					})}
				</Table.Row>
			</Table.Header>
			{multiple ? children : <Table.Body>{children}</Table.Body>}
		</Table>
	</div>
);

export const CellValue = ({ value }) => {
	switch (typeof value) {
		case 'boolean':
			return (
				<Table.Cell positive={value} negative={!value}>
					{value ? 'Oui' : 'Non'}
				</Table.Cell>
			);
		case 'string':
		case 'number':
			return <Table.Cell>{value}</Table.Cell>;
		default:
			return <Table.Cell></Table.Cell>;
	}
};

/**
 * @prop {array} table
 * @prop {array} cols
 */
const TableDefault = ({ table = [], cols, ...props }) => (
	<TableLayout cols={cols} {...props}>
		{table.map((el, i) => (
			<Table.Row key={i}>
				{cols.map(col => (
					<CellValue value={el[col.key]} key={col.key} />
				))}
			</Table.Row>
		))}
	</TableLayout>
);

export default TableDefault;
