import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setBodySplit } from '../../reducers/windowReducer';

const useDragModal = id => {
	const modalRef = useRef(null);
	const mouseOffsetRef = useRef({ x: 0, y: 0 }); // coordonnées stockées de la souris par rapport à la modal, pour mesurer le déplacement
	const dispatch = useDispatch();
	const bodySplit = useSelector(state => state.window.bodySplit); // le body ne prend qu'une moitié de la fenêtre, pour laisser l'autre à la modal
	const [isDragging, setIsDragging] = useState(false); // l'utilisateur maintient le clique sur la modal et la déplace
	const [splitting, setSplitting] = useState(false); // l'utilisateur à déplacer la modal sur une des extrémités horizontales de la fenêtre
	const [split, setSplit] = useState(false); // la modal coupe la fenêtre en deux

	useEffect(() => {
		if (isDragging) {
			document.addEventListener('mousemove', handleMouseMove);
			document.addEventListener('mouseup', handleMouseUp);
		} else {
			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('mouseup', handleMouseUp);
		}

		return () => {
			if (isDragging) {
				document.removeEventListener('mousemove', handleMouseMove);
				document.removeEventListener('mouseup', handleMouseUp);
			}
		};
	}, [isDragging]);

	useEffect(() => {
		if (!bodySplit && split) {
			// Si le body n'est pas split et que la modal est split, on désactive le split
			// On ne passe pas par handleUnsplit, car cette fonction sert à vérifier l'état du body par rapport aux modals restantes
			setSplit(false);
		}
	}, [bodySplit]);

	useEffect(() => {
		if (split) {
			handleFocus();
		}
	}, [split]);

	const updateSplitting = (nextX, maxX) => {
		if (split) return;

		if (nextX > maxX) {
			setSplitting('right');
		} else if (nextX < 0) {
			setSplitting('left');
		} else {
			setSplitting(false);
		}
	};

	const handleResetPosition = () => {
		if (modalRef.current) {
			modalRef.current.style.left = null;
			modalRef.current.style.top = null;
			modalRef.current.style.transform = null;
		}
	};

	const handleMouseMove = e => {
		if (isDragging && modalRef.current) {
			// différence entre l'ancienne et la nouvelle position de la souris
			const nextX = e.clientX - mouseOffsetRef.current.x;
			const nextY = e.clientY - mouseOffsetRef.current.y;
			// largeur de la fenêtre - largeur de la modal, pour déterminer la position maximale de la modal
			const maxX = window.innerWidth - modalRef.current.offsetWidth;
			const maxY = window.innerHeight - modalRef.current.offsetHeight;
			// Contraint la modal à la taille de la fenêtre pour éviter qu'elle ne sorte de la fenêtre
			modalRef.current.style.left = Math.max(0, Math.min(nextX, maxX)) + 'px';
			modalRef.current.style.top = Math.max(0, Math.min(nextY, maxY)) + 'px';
			modalRef.current.style.transform = 'none';

			updateSplitting(nextX, maxX);
		}
	};

	const handleMouseUp = () => {
		setIsDragging(false);
		// on passe par modalRef.current car à ce moment-là, le state splitting n'est pas à mis à jour
		const splitting = modalRef.current
			? modalRef.current.classList.contains('splitting') &&
			  modalRef.current.dataset.split
			: false;
		if (splitting) {
			if (!bodySplit) {
				dispatch(setBodySplit(splitting === 'right' ? 'left' : 'right'));
			}
			setSplit(splitting);
			setSplitting(false);
		}
	};

	const handleMouseDown = e => {
		if (
			!e.target.classList.contains('grabble') &&
			!e.target.classList.contains('sub-grabble')
		) {
			// Le maintient du clique ne fonctionne que sur la modal (.grabble) et ces enfants qui le permmettent (.sub-grabble)
			return;
		}

		e.stopPropagation();
		e.preventDefault();
		e.cancelBubble = true;
		e.returnValue = false;

		handleFocus();
		setIsDragging(true);

		modalRef.current = document.getElementById(id);
		if (modalRef.current) {
			const modalCoords = modalRef.current.getBoundingClientRect();
			mouseOffsetRef.current = {
				x: e.clientX - modalCoords.left, // coordonnées horizontales (x) de la souris - la position left de la modal
				y: e.clientY - modalCoords.top, // coordonnées verticales (y) de la souris - la position top de la modal
			};
		}
	};

	const handleFocus = (_split = split) => {
		const modals = document.getElementsByClassName('ui modal portaled');
		for (let modal of modals) {
			if (modal.id === id) {
				// met en avant la modal sur laquelle on vient de cliquer
				modal.style.zIndex = _split ? 994 : 998;
			} else {
				// met les autres en arrière plan
				modal.style.zIndex = modal.classList.contains('split') ? 993 : 997;
			}
		}
	};

	// Vérifie si le body doit s'unsplit, s'il n'y a plus de modal split
	const handleUnsplit = () => {
		if (split) {
			setSplit(false);
			const modals = document.getElementsByClassName('ui modal portaled split');
			// Si il n'y avait qu'une seule modal, on désactive le split du body
			if (modals.length < 2) {
				dispatch(setBodySplit(false));
			}
		}
	};

	return {
		isDragging,
		splitting,
		split,
		handleMouseDown,
		handleFocus,
		handleUnsplit,
		handleResetPosition,
	};
};

export default useDragModal;
