import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Scroll to top after each route change
const useResetScroll = paddingTop => {
	const { pathname, hash } = useLocation();

	useEffect(() => {
		if (!hash) {
			window.scrollTo(0, 0);
		} else {
			// scroll to #id
			const el = document.getElementById(hash.replace('#', ''));
			if (el) {
				window.scrollTo(
					0,
					el.getBoundingClientRect().top + window.scrollY - paddingTop
				);
			}
		}
	}, [pathname]);
};
export default useResetScroll;
