import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon, Modal, Portal } from 'semantic-ui-react';
import useDragModal from '../hooks/modal/useDragModal';
import useMinimizeModal from '../hooks/modal/useMinimizeModal';
import { getPortalWidth } from '../reducers/windowReducer';
import { makeClassName } from '../functions/ui';

const DraggableModal = ({
	id,
	title,
	content,
	children,
	footer,
	className,
	size,
	open,
	handleOpen,
	handleClose,
	...props
}) => {
	const {
		isDragging,
		splitting,
		split,
		handleMouseDown,
		handleFocus,
		handleResetPosition,
		handleUnsplit,
	} = useDragModal(id);

	const subscribe = minimized => {
		if (minimized) {
			handleUnsplit();
			handleResetPosition();
		} else {
			handleFocus();
		}
	};

	// prettier-ignore
	const { dataProps, minimized, tabAnimated, onClose, onOpen, handleMinimize } = useMinimizeModal({
		id,
		handleClose: () => {
			handleUnsplit();
			handleClose();
		},
		handleOpen,
		subscribe,
	});

	const portalWidth = useSelector(state => getPortalWidth(state, split));
	return (
		<>
			<Portal
				open={open}
				onClose={onClose}
				onOpen={onOpen}
				// portal close conditions :
				closeOnTriggerBlur={false}
				closeOnTriggerClick={false}
				closeOnDocumentClick={false}
				closeOnPortalMouseLeave={false}
				closeOnTriggerMouseLeave={false}
				closeOnEscape={true}
			>
				<div
					{...props}
					{...dataProps}
					style={
						split && !minimized
							? { width: `calc(${portalWidth} - 0.25em)` }
							: undefined
					}
					className={makeClassName(
						'ui modal portaled',
						className,
						size,
						// can't be grabble if minimized or split, but can be split when minimized
						minimized ? 'minimized' : !split && 'grabble',
						split && 'split',
						split,
						splitting && 'splitting',
						isDragging && 'grabbing',
						tabAnimated && 'tab-animated'
					)}
					data-split={split || splitting}
					id={id}
					onMouseDown={handleMouseDown}
				>
					<Modal.Header className="flex sub-grabble align-items-start justify-content-between">
						<div className="flex sub-grabble align-items-start">
							<Icon name="arrows alternate" className="sub-grabble" />
							<h2 className="text-overflow mt-0 ml-0_5 sub-grabble">
								{title}
							</h2>
						</div>
						<div className="sub-grabble white-space-nowrap">
							<Button
								icon={'window ' + (minimized ? 'maximize' : 'minimize')}
								onClick={handleMinimize}
								className="mr-0 ml-1 p-0_5"
							/>
							<Button
								icon="close"
								onClick={e => {
									e.preventDefault();
									e.stopPropagation();
									onClose(e);
								}}
								className="mr-0 ml-0_5 p-0_5"
							/>
						</div>
					</Modal.Header>
					<Modal.Content className="sub-grabble">
						{children || content}
					</Modal.Content>
					{!!footer && (
						<Modal.Footer className="sub-grabble">{footer}</Modal.Footer>
					)}
				</div>
			</Portal>
			{splitting && (
				// calque flou qui apparait dans la future position de la modal lors du splitting
				<Portal open={!!splitting}>
					<div className={makeClassName('splitter', splitting)} />
				</Portal>
			)}
		</>
	);
};

export default DraggableModal;
