import { configureStore } from '@reduxjs/toolkit';
import accountingReducer from './reducers/accountingReducer';
import dataReducer from './reducers/dataReducer';
import darkModeReducer from './reducers/darkModeReducer';
import exercisesReducer from './reducers/exercisesReducer';
import queriesReducer from './reducers/queriesReducer';
import messagesReducer from './reducers/messagesReducer';
import userReducer from './reducers/userReducer';
import windowReducer from './reducers/windowReducer';

const store = configureStore({
	reducer: {
		user: userReducer,
		accounting: accountingReducer,
		exercises: exercisesReducer,
		messages: messagesReducer,
		darkMode: darkModeReducer,
		queries: queriesReducer,
		data: dataReducer,
		window: windowReducer,
	},
});
export default store;
