import React from 'react';
import { Button } from 'semantic-ui-react';

export const buttonPrintProps = {
	icon: 'print',
	// content: 'Imprimer',
	className: 'none-max-md',
	animated: true,
	basic: true,
	title: 'imprimer',
};

const ButtonPrint = props => <Button {...buttonPrintProps} {...props} />;
export default ButtonPrint;
