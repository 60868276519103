import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @returns {object}
 */
const useQuery = (defaultValue = {}) => {
	const { search } = useLocation();

	const query = useMemo(
		() =>
			search
				.substring(1) // removes "?"
				.split('&')
				.reduce(
					(paramsObject, param) => {
						let [key, value] = param.split('=');
						if (key && value !== undefined) {
							value = decodeURIComponent(value);
							// Commenté car cause un problème dans la vérifications des trippleOptions, on attend '1' et pas 1, modifier aussi trippleOptions si necessaire.
							// if ((value && !isNaN(value)) || value == '0') {
							// 	value = Number(value);
							// }

							const cesure = key.indexOf('[');
							if (cesure != -1) {
								const supKey = key.slice(0, cesure);
								key = key.slice(cesure + 1, -1);
								if (!paramsObject[supKey]) {
									paramsObject[supKey] = {};
								}
								paramsObject[supKey][key] = value;
							} else {
								paramsObject[key] = value;
							}
						}
						return paramsObject;
					},
					{ ...defaultValue }
				),
		[search]
	);

	return query;
};

export default useQuery;
