import React from 'react';
import { IMAGE_FILE, PDF_FILE } from '../constants/files';
import { makeClassName } from '../functions/ui';
import useDropFile from '../hooks/file/useDropFile';

export const IMAGE = 'image';
export const PDF = 'pdf';
export const EXCEL = 'excel';
export const ZIP = 'zip';
export const TXT = 'txt';
export const XLSX = 'xlsx';
export const megaBytesToBits = 1048576;

export const typesVerfied = {
	image: {
		authorized: 'png\u00A0; jpg\u00A0; jpeg\u00A0; gif', //webp\u00A0;
		accept: IMAGE_FILE, //,image/webp
		icon: 'image',
	},
	pdf: {
		authorized: 'pdf',
		accept: PDF_FILE,
		icon: 'pdf file',
	},
	excel: {
		authorized: 'xlsx\u00A0; xls\u00A0; csv',
		accept: '.xlsx, .xls, .csv',
		icon: 'excel file',
	},
	zip: {
		authorized: 'zip',
		accept: 'application/zip,application/x-zip-compressed,application/x-compressed',
		icon: 'file archive',
	},
	txt: {
		authorized: 'txt',
		accept: 'text/plain',
		icon: 'file text',
	},
};

const FileInput = ({
	name = 'file',
	id = name,
	labelProps,
	types = [PDF],
	nbMax = 1,
	maxMo = 50,
	handleFiles,
	handleError,
	...props
}) => {
	const handleChange = files => {
		let send_files = [];
		if (files.length) {
			for (let [i, file] of Object.entries(files)) {
				i = parseInt(i);
				if (i > nbMax - 1) {
					handleError(
						`Vous essayez d'importer plus ${
							nbMax > 1 ? `de ${nbMax} fichiers.` : `d'un fichier.`
						}.`
					);
					break;
				}
				// if (
				// 	types.includes(IMAGE) &&
				// 	file.type !== 'image/jpeg' &&
				// 	file.type !== 'image/png' &&
				// 	// && file.type !== 'image/webp'
				// 	file.type !== 'image/gif'
				// ) {
				// 	handleError(`Le format du fichier ${i + 1} est incorrecte.`);
				// 	continue;
				// }
				if (file.size > maxMo * megaBytesToBits) {
					// prettier-ignore
					handleError(`Le fichier ${(i + 1)} est trop volumineux, il fait plus de ${maxMo}Mo.`);
					continue;
				}
				send_files.push(file);
			}
			handleFiles(send_files);
		}
	};

	const { dragging, handleDrag, handleDrop } = useDropFile(
		handleChange,
		props.disabled
	);
	const accept = types.map(type => typesVerfied[type].accept).join(',');

	return (
		<>
			<label
				htmlFor={id}
				{...labelProps}
				className={makeClassName(labelProps?.className, dragging && 'dragging')}
				onDragEnter={handleDrag}
				onDragLeave={handleDrag}
				onDragOver={handleDrag}
				onDrop={handleDrop}
			/>
			<input
				id={id}
				name={name}
				className="none"
				accept={accept}
				onChange={e => handleChange(e.target.files)}
				multiple={nbMax > 1 ? 'multiple' : false}
				{...props}
				type="file"
			/>
		</>
	);
};

export default FileInput;
