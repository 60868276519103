import React from 'react';
import { useSelector } from 'react-redux';
import { MESSAGE_LOADER, useInitLocalRequest } from './hooks/fetch/useLocalFetch';
import Footer from './layout/Footer';
import PageRouter from './routers/PageRouter';
import PageError from './pages/PageError';
import WindowSlider from './modals/WindowSlider';
import useDarkMode from './hooks/session/useDarkMode';
import useTokenManager from './hooks/fetch/useTokenManager';
import { makeClassName } from './functions/ui';
import ErrorBoundary from './ErrorBoundary';

function App() {
	const [darkMode] = useDarkMode();
	const { bodySplit, bodyWidth } = useSelector(state => state.window);
	const initRequest = useInitLocalRequest();
	useTokenManager({
		redirection: null,
		escapeRegex: /^\/(token|client-id).*$/,
		params: initRequest(),
		loadingIcon: MESSAGE_LOADER,
	});

	return (
		<>
			<div
				className={makeClassName(
					'app',
					bodySplit && 'split',
					bodySplit,
					darkMode && 'dark-mode'
				)}
				style={bodySplit ? { width: `calc(${bodyWidth} - 0.25em)` } : undefined}
			>
				<main>
					<ErrorBoundary Component={PageError}>
						<PageRouter />
					</ErrorBoundary>
				</main>
				<Footer />
			</div>
			{bodySplit && <WindowSlider />}
		</>
	);
}

export default App;
