const useLocalStorage = () => {
	const getStoredItem = key => {
		try {
			const storedItem = localStorage.getItem(key);
			if (storedItem == null) return null;
			return JSON.parse(storedItem);
		} catch (error) {
			console.error(error);
			return null;
		}
	};

	const setItemToStorage = (key, item) => {
		try {
			if (item == null) localStorage.removeItem(key);
			localStorage.setItem(key, JSON.stringify(item));
		} catch (error) {
			console.error(error);
		}
	};

	return { getStoredItem, setItemToStorage };
};

export default useLocalStorage;
