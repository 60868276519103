import { useEffect, useMemo, useState } from 'react';
import useLocalStorage from '../session/useLocalStorage';

// more the object is heavy and more this is better than objectCompare
const areDifferent = (v1: any, v2: any) => {
	return (
		v1 !== v2 &&
		(Object.keys(v1).length != Object.keys(v2).length ||
			JSON.stringify(v1) !== JSON.stringify(v2))
	);
};

const useStoreForm = ({
	storedKey,
	defaultValueGiven,
	initDefaultValue,
	canStore = true,
}: {
	storedKey: string;
	defaultValueGiven: any;
	initDefaultValue?: Function;
	canStore?: boolean;
}) => {
	const { getStoredItem, setItemToStorage } = useLocalStorage();

	const storeValueTimed = (storedKey: string, value: any) => {
		const time = new Date().getTime();
		setItemToStorage(storedKey, { value, time });
		return time;
	};
	const getStoredValueTimed = (storedKey: string) => {
		const storedItem = getStoredItem(storedKey);
		if (!storedItem) return null;

		const currentTime = new Date().getTime();
		if (storedItem.time) {
			const { value, time } = storedItem;
			const oneHourLater = time + 60 * 60 * 1000;
			if (currentTime < oneHourLater) {
				return value;
			}
		}

		setItemToStorage(storedKey, { value: null, time: currentTime });
		return null;
	};

	const [defaultValue, setDefaultValue] = useState(() =>
		initDefaultValue ? initDefaultValue(defaultValueGiven) : defaultValueGiven
	);
	const [storedValue, setStoredValue] = useState(
		() => (canStore && getStoredValueTimed(storedKey)) || defaultValue
	);

	// current form value
	const [form, setForm] = useState(storedValue);

	const changes = useMemo(() => {
		return !!form && areDifferent(form, defaultValue);
	}, [form, defaultValue]);

	useEffect(() => {
		// check storedValue to avoid first render erase stored value
		if (canStore && form !== storedValue && (storedValue || form !== defaultValue)) {
			storeValueTimed(storedKey, form);
			setStoredValue(form);
		}
	}, [form]);

	const setDefaultValueBuffer = (name: string, value: any) => {
		const nextDefaultValue = name ? { ...defaultValue, [name]: value } : value;
		setDefaultValue(nextDefaultValue);
		if (form === defaultValue) {
			setForm(nextDefaultValue);
		}
	};

	const voidStorage = () => {
		storeValueTimed(storedKey, null);
		setStoredValue(null);
	};

	const resetForm = () => {
		voidStorage();
		setForm(defaultValue);
	};

	return {
		form,
		setForm,
		defaultValue,
		setDefaultValue: setDefaultValueBuffer,
		resetForm,
		voidStorage,
		changes,
	};
};
export default useStoreForm;
