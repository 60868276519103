/**
 * After Date.toLocaleDateString()
 * @param {string} date
 * @return {string}
 */
export const deformatDate = date => date.split('/').reverse().join('-');

/**
 * @param {string} date
 * @return {string}
 */
export const formatDate = date => date.split('-').reverse().join('/');

/**
 * @param {Date} date
 * @return {string}
 * date.toISOString() = bad, so replace it
 */
export const dateToKebabCase = date =>
	date.getFullYear() +
	'-' +
	('0' + (date.getMonth() + 1)).slice(-2) +
	'-' +
	('0' + date.getDate()).slice(-2);

/**
 * Need year for leap years
 * @param {Date} date
 * @return {int}
 */
export const getMonthLastDay = (month, year) => new Date(year, month + 1, 0).getDate();

/** @return {Date} */
export const getNextMonthLastDay = () => {
	let date = new Date();
	return new Date(date.getFullYear(), date.getMonth() + 2, 0);
};

export const TODAY_DATE = new Date();
export const CURRENT_YEAR = TODAY_DATE.getFullYear();
export const CURRENT_MONTH = ('0' + (TODAY_DATE.getMonth() + 1)).slice(-2);
export const CURRENT_DAY = ('0' + TODAY_DATE.getDate()).slice(-2);
export const TODAY_FORMETTED_DATE =
	CURRENT_DAY + '/' + CURRENT_MONTH + '/' + CURRENT_YEAR;
export const INPUT_TODAY_DATE = CURRENT_YEAR + '-' + CURRENT_MONTH + '-' + CURRENT_DAY;

/**
 * Need year for leap years
 * @param {string|number} startDay
 * @param {string|number} startMonth
 * @return {string}
 */
export const getExerciseStartDate = (startDay, startMonth) => {
	startDay = ('0' + startDay).slice(-2);
	startMonth = ('0' + startMonth).slice(-2);
	let exerciseString = CURRENT_YEAR + `-${startMonth}-${startDay}`;
	if (exerciseString > INPUT_TODAY_DATE) {
		exerciseString = CURRENT_YEAR - 1 + `-${startMonth}-${startDay}`;
	}
	return exerciseString;
};
/**
 * @param {string} exerciseStartDate
 * @return {string}
 */
export const getExerciseEndDate = exerciseStartDate => {
	const exerciseEndDate = new Date(exerciseStartDate);
	exerciseEndDate.setFullYear(exerciseEndDate.getFullYear() + 1);
	exerciseEndDate.setDate(exerciseEndDate.getDate() - 1);
	return dateToKebabCase(exerciseEndDate);
};

export const exercisesToOptions = (exercises, defaultValue = {}) =>
	exercises.reduce((accu, el, i) => {
		accu[deformatDate(el.start)] = el.start + ' au ' + el.end;
		return accu;
	}, defaultValue);
