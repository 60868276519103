import React from 'react';
import { FormInput } from 'semantic-ui-react';
import { roundFloat } from '../functions/numbers';

const InputNumberControlled = ({
	value,
	price = false,
	percent = false,
	decimal = price || percent ? 2 : false, // false ! 0 here
	max = percent ? 100 : undefined,
	min = percent ? 0 : undefined,
	icon,
	onChange,
	innerRef,
	...props
}) => (
	<FormInput
		type="number"
		step={decimal ? `0.${'0'.repeat(decimal - 1)}1` : '1'}
		max={max}
		min={min}
		value={
			value === undefined || value === null
				? ''
				: decimal
				? roundFloat(value, decimal)
				: value
		}
		icon={
			props.children
				? undefined
				: icon || (percent ? 'percent' : price ? 'euro' : undefined)
		}
		onChange={(e, input) => {
			if (input.value === '' || input.value === '-') {
				onChange(e, input);
				return;
			}
			input.value = decimal ? roundFloat(input.value, decimal) : input.value;
			if (max !== undefined && input.value > max) {
				input.value = max;
			} else if (min !== undefined && input.value < min) {
				input.value = min;
			}
			onChange(e, input);
		}}
		ref={innerRef}
		{...props}
	/>
);

export default InputNumberControlled;
