import React, { Component } from 'react';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };

		this.clearError = this.clearError.bind(this);
	}

	static getDerivedStateFromError(error) {
		// Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.
	}

	clearError() {
		this.setState({ hasError: false });
	}

	render() {
		if (this.state.hasError) {
			// UI de repli.
			const { Component } = this.props;
			return <Component clearError={this.clearError} />;
		}

		return this.props.children;
	}
}
export default ErrorBoundary;
