import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDarkModeAction } from '../../reducers/darkModeReducer';
import useLocalStorage from './useLocalStorage';

export const DARK_MODE_KEY = 'darkMode';
const useDarkMode = () => {
	const darkMode = useSelector(state => state.darkMode);
	const dispatch = useDispatch();
	const { getStoredItem, setItemToStorage } = useLocalStorage();

	useEffect(() => {
		const storedDarkMode = getStoredItem(DARK_MODE_KEY);
		if (storedDarkMode !== darkMode) {
			dispatch(setDarkModeAction(storedDarkMode));
		}
	}, []);

	useEffect(() => {
		const storedDarkMode = getStoredItem(DARK_MODE_KEY);
		if (darkMode != null && storedDarkMode !== darkMode) {
			setItemToStorage(DARK_MODE_KEY, darkMode);
		}
	}, [darkMode]);

	const toggleDarkMode = () => {
		dispatch(setDarkModeAction(!darkMode));
	};

	return [darkMode, toggleDarkMode];
};

export default useDarkMode;
