import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	portals: [],
	bodySplit: false,
	bodyWidth: '50vw',
};

export const windowSlice = createSlice({
	name: 'window',
	initialState,
	reducers: {
		addPortal: (state, { payload: portal }) => {
			state.portals.push(portal);
		},
		removePortal: (state, { payload: id }) => {
			state.portals = state.portals.filter(
				_id => _id !== id && document.getElementById(id) // just in case when some modals have missed deletion
			);
		},
		setBodySplit: (state, { payload: bodySplit }) => {
			if (bodySplit != state.bodySplit) {
				state.bodyWidth = initialState.bodyWidth;
				state.bodySplit = bodySplit;
			}
		},
		setBodyWidth: (state, { payload: bodyWidth }) => {
			state.bodyWidth = bodyWidth;
		},
		resetWindow: () => initialState,
	},
	selectors: {
		getPortalIndexById: (state, id) => {
			return state.portals.indexOf(id);
		},
		getPortalWidth: (state, portalSplit) =>
			portalSplit == state.bodySplit
				? state.bodyWidth
				: `100vw - ${state.bodyWidth}`,
	},
});

export const { addPortal, removePortal, setBodySplit, resetWindow, setBodyWidth } =
	windowSlice.actions;

export const { getPortalIndexById, getPortalWidth } = windowSlice.selectors;

export default windowSlice.reducer;
