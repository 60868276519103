// modules
import { useNavigate } from 'react-router-dom';

const useGoBack = () => {
	const navigate = useNavigate();

	const goBack = goBackRoute => {
		if (goBackRoute) {
			navigate(goBackRoute, { replace: true });
		} else {
			navigate(-1);
		}
	};

	return goBack;
};

export default useGoBack;
