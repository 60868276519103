import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addMessage } from '../../reducers/messagesReducer';
import { removeUser } from '../../reducers/userReducer';


const useLogout = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const logout = useCallback(() => {
		// Save storage keys to keep before remove storage
		const values =
			process.env.REACT_APP_LOCAL_KEYS_KEPT &&
			process.env.REACT_APP_LOCAL_KEYS_KEPT.split(',').reduce((dict, key) => {
				const value = localStorage.getItem(key);
				if (value != null) {
					dict[key] = value;
				}
				return dict;
			}, {});

		// Remove localStorage
		localStorage.clear();
		// Set again keys to keep
		if (values) {
			Object.entries(values).forEach(([key, value]) => {
				localStorage.setItem(key, value);
			});
		}

		dispatch(addMessage({ value: 'Déconnecté', error: true }));
		dispatch(removeUser());
		navigate('/', { replace: true });
	}, [navigate, dispatch]);

	return logout;
};

export default useLogout;
