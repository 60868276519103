// modules
import React, { useEffect, useState } from 'react';
import { FormTextArea } from 'semantic-ui-react';

/** 
 * Value should be controlled
 * id props is required in array fields 
 */
function TextAreaAutoRows({ rows: minRows = 1, ...props }) {
	const [autoRows, setAutoRows] = useState(minRows);
	const [textArea, setTextArea] = useState(null);

	useEffect(() => {
		setTextArea(document.getElementById(props.id || props.name));
	}, []);

	useEffect(() => {
		if (textArea) {
			timeQueue(props.value);
		}
	}, [props.value, textArea]);

	const timeQueue = value => {
		setTimeout(() => {
			if (props.value === value) {
				calcAutoRows(textArea);
			}
		}, 500);
	};

	const calcAutoRows = el => {
		const style = window.getComputedStyle
			? window.getComputedStyle(el)
			: el.currentStyle;
		const lineHeight = parseFloat(style.lineHeight || 1, 10);
		let height = calculateContentHeight(el, lineHeight);
		const newRows = Math.round(height / lineHeight);
		const nextRows = newRows || parseInt(minRows);
		if (nextRows >= minRows && nextRows !== autoRows) setAutoRows(nextRows);
	};

	const calculateContentHeight = (el, lineHeight) => {
		let height = el.offsetHeight;
		const scrollHeight = el.scrollHeight;
		// only bother if the el is bigger than content
		if (height >= scrollHeight) {
			// check that our browser supports changing dimension
			// calculations mid-way through a function call...
			el.style.height = height + lineHeight + 'px';
			// because the scrollbar can cause calculation problems
			el.style.overflow = 'hidden';
			// by checking that scrollHeight has updated
			if (scrollHeight < el.scrollHeight) {
				// now try and scan the el's height downwards
				// until scrollHeight becomes larger than height
				while (el.offsetHeight >= el.scrollHeight) {
					height -= lineHeight;
					el.style.height = height + 'px';
				}
				// be more specific to get the exact height
				while (el.offsetHeight < el.scrollHeight) {
					height++;
					el.style.height = height + 'px';
				}
				// reset style
				el.style.height = null;
				el.style.overflow = null;
				// remove 15px
				return height - 15;
			}
			// reset style
			el.style.height = null;
			el.style.overflow = null;
		}
		// remove 15px
		return scrollHeight - 15;
	};

	return <FormTextArea id={props.name} {...props} rows={autoRows} />;
}
export default TextAreaAutoRows;
