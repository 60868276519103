// modules
import { useEffect, useRef, useState } from 'react';
import { GET } from '../../constants/methods';
import { FILE_TYPE, HTML_TYPE, JSON_TYPE } from '../../constants/requestType';
import { buildFormData } from '../../functions';
// functions

const CONTENT_TYPE = 'Content-Type';

const useFetch = ({
	handleResult,
	firstCallPriority = true,
	manageError = manageFetchError,
}) => {
	const unmounted = useRef(false);
	const [controller, setController] = useState();
	const [loading, setLoading] = useState(false);

	// If component is unmounted result can't be updated
	useEffect(
		() => () => {
			unmounted.current = true;
			controller?.abort();
		},
		[]
	);

	const transmitResult = result => {
		if (result) {
			if (!unmounted.current) {
				setLoading(false);
			}
			handleResult(result);
		}
	};

	const refreshController = () => {
		controller?.abort();
		let nextController = new AbortController();
		setController(nextController);
		return nextController;
	};

	const load = async ({
		url = '',
		method = GET,
		body,
		contentType = JSON_TYPE,
		headers = {},
	}) => {
		// check if there is a current request and abort it or current one, depending on firstCallPriority
		let controllerOutState = controller;
		if (loading) {
			// doens't permit to refetch
			if (firstCallPriority) return;
			// abort previous request and prepare a new one
			controllerOutState = refreshController();
		} else if (!controller) {
			controllerOutState = refreshController();
		}

		if (!unmounted.current) setLoading(true);

		const params = {
			method,
			headers,
			signal: controllerOutState.signal,
			redirect: 'follow',
		};

		if (body) {
			params.body = manageContentType({ contentType, headers, body });
		}

		manageFetch(url, params)
			.then(response => {
				if (!unmounted.current) transmitResult(response);
			})
			.catch(e => {
				if (!unmounted.current) transmitResult(manageError(e, url, params));
			});
	};
	return [load, loading, unmounted.current];
};

const manageContentType = ({ contentType, headers, body }) => {
	if (contentType === JSON_TYPE) {
		headers[CONTENT_TYPE] = JSON_TYPE;
		return JSON.stringify(body);
	}
	if (contentType === FILE_TYPE) {
		return buildFormData(body);
	}
	headers[CONTENT_TYPE] = contentType;
	return body;
};

export const manageFetch = (url, params) => {
	let status = null;
	let name = null;
	return fetch(url, params)
		.then(response => {
			status = response.status;
			if (response.headers.get(CONTENT_TYPE).includes(HTML_TYPE)) {
				const error = new Error('Erreur : ressource introuvable.');
				error.status = 404;
				throw error;
			}
			if (response.headers.get(CONTENT_TYPE) === JSON_TYPE) {
				return response.json();
			}
			name = response.headers.get('Content-Disposition');
			return response.blob();
		})
		.then(response => {
			if (status < 200 || status >= 300) throw response;
			response.success = true;
			response.name = name;
			return response;
		})
		.catch(error => {
			if (!error) {
				error = {};
			} else if (typeof error !== 'object') {
				error = { message: error };
			}
			if (!error.status) {
				if (status) {
					error.status = status;
				} else {
					status = 400;
				}
			}
			throw error;
		});
};

export const manageFetchError = error => {
	if (!error) {
		error = {};
	} else if (typeof error !== 'object') {
		error = { message: error };
	}
	error.success = false;

	if (
		error.name === 'AbortError' ||
		error.message === 'The user aborted a request.' ||
		error.message === 'The operation was aborted' ||
		error.message === 'This operation was aborted'
	) {
		return;
	}

	console.error(error.status, error.message || error.errors); // log before changing message

	if (error.message === 'Failed to fetch') {
		error.message =
			'Veuillez vérifier votre connexion internet. La communication avec le serveur est impossible.';
	}
	if (
		error.title === 'An error occurred' ||
		error.message === 'An error occurred' ||
		(typeof error.message === 'string' &&
			(error.message.startsWith('Unexpected') || error.message.startsWith('JSON')))
	) {
		error.message =
			'Une erreur inattendu est survenue dans la gestion de la demande.';
	}
	// Set as result
	return error;
};

export default useFetch;
