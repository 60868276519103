import { createSlice } from '@reduxjs/toolkit';

export const queriesSlice = createSlice({
	name: 'queries',
	initialState: {},
	reducers: {
		setQueries: (state, { payload: queries }) => queries || state,
		saveQueryToPath: {
			reducer: (state, { payload: { key, query } }) => {
				state[key] = query;
			},
			prepare: (key, query) => ({ payload: { key, query } }),
		},
		removeQueries: () => ({}),
	},
});

export const { setQueries, saveQueryToPath, removeQueries } = queriesSlice.actions;
export default queriesSlice.reducer;
