import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import ModalCreateEntry from '../modals/ModalCreateEntry';
import { LoadContext } from '../context/LoadContext';

const MenuAccounting = () => {
	const accounting = useSelector(state => state.accounting);
	const { reload } = useContext(LoadContext) || {};

	// TODO repsonsive remove position fixed and menu vertical
	return (
		<div className="main-menu">
			<Menu vertical>
				<Menu.Item name="accountings">
					<h3 className="m-0">
						<NavLink to="/" className="text-bold text-primary" end>
							MY.Compta
						</NavLink>
					</h3>
				</Menu.Item>
				<Menu.Item
					as={NavLink}
					to={`/accountings`}
					name="accountings"
					content="Mes Comptabilités"
					className="not-responsive"
					end
				/>
				<Menu.Item
					as={NavLink}
					to={`/accountings/${accounting.id}`}
					name="balance"
					content="Balance"
					end
				/>
				<Menu.Item
					as={NavLink}
					to={`/accountings/${accounting.id}/ledger`}
					name="ledger"
					content="Grand Livre"
				/>
				<Menu.Item
					as={NavLink}
					to={`/accountings/${accounting.id}/journals`}
					name="journals"
					content="Journaux"
				/>
				<Menu.Item
					as={NavLink}
					to={`/accountings/${accounting.id}/accounts`}
					name="accounts"
					content="Comptes"
				/>
				<Menu.Item className="buttoned">
					<ModalCreateEntry handleSuccess={reload} />
				</Menu.Item>
			</Menu>
		</div>
	);
};
export default MenuAccounting;
