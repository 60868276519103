import useLocalFetch from '../useLocalFetch';
import { POST } from '../../../constants/methods';
import { FILE_TYPE } from '../../../constants/requestType';

function useUpload({ url, callback }) {
	const [load, loading] = useLocalFetch({
		handleResult: result => {
			if (result.success) {
				if (callback) callback(result);
			}
		},
	});

	const handleSubmit = (body, urlAsync = url) => {
		load({
			url: urlAsync,
			method: POST,
			contentType: FILE_TYPE,
			body,
		});
	};

	return { loading, handleSubmit };
}

export default useUpload;
