import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
	name: 'user',
	initialState: { loading: true },
	reducers: {
		setUser: (state, { payload: user }) => user || state,
		updateUser: (state, { payload: user }) => {
			// update only user data not user state and auth
			if (state.token) delete user.token;
			if (state.refreshToken) delete user.refreshToken;
			if (state.loading) delete user.loading;
			return { ...state, ...user };
		},
		removeUser: () => ({}),
		setUserValue: {
			reducer: (state, { payload: { name, value } }) => {
				state[name] = value;
			},
			prepare: (name, value) => ({ payload: { name, value } }),
		},
		setUserLoading: (state, { payload: loading }) => {
			state.loading = loading;
		},
		setToken: {
			reducer: (state, { payload }) => {
				state.token = payload.token;
				if (payload.refreshToken) {
					state.refreshToken = payload.refreshToken;
				}
			},
			prepare: (token, refreshToken) => ({ payload: { token, refreshToken } }),
		},
	},
});

export const { setUser, updateUser, removeUser, setUserValue, setUserLoading, setToken } =
	userSlice.actions;

export default userSlice.reducer;
