import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SearchPreviewed from './SearchPreviewed';
import useLocalFetch from '../hooks/fetch/useLocalFetch';
import { typeOf } from '../functions';

const SearchAccount = ({ account, keyName, error, label, ...props }) => {
	const accounting = useSelector(state => state.accounting);
	const [result, setResult] = useState(); // legacy from SearchField
	const [load, loading] = useLocalFetch({
		firstCallPriority: false,
		handleResult: setResult,
	});

	const handleLoad = search => {
		if (search != undefined && search !== '') {
			// TODO search by account label too
			load({ url: `accountings/${accounting.id}/accounts/search/${search}` });
		} else {
			setResult(undefined);
		}
	};

	return (
		<SearchPreviewed
			create
			returnFullObject
			value={account ? account[keyName] : ''}
			error={error ? (typeOf(error) == 'object' ? error[keyName] : error) : null}
			label={label}
			titleCallback={account =>
				(account.number == undefined ? '' : account.number) +
				` : ` +
				(account.label == undefined ? '' : account.label)
			}
			keyReturn={keyName}
			resultKey="accounts"
			handleLoad={handleLoad}
			preloadTrigger={null}
			loading={loading}
			result={result}
			{...props}
		/>
	);
};

export default SearchAccount;
