import useModal from '../hooks/modal/useModal';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import EntryForm from '../forms/EntryForm';
import DraggableModal from './DraggableModal';
import { Link, useLocation } from 'react-router-dom';

const ModalCreateEntry = ({
	label = 'Créer une écriture',
	id = 'modal-create-entry',
	handleSuccess,
	btnClassName,
}) => {
	const { pathname } = useLocation();
	const accounting = useSelector(state => state.accounting);
	const [open, handleOpen, handleClose] = useModal();

	const onSuccess = result => {
		handleClose();
		handleSuccess(result);
	};

	const handleClickBuffer = e => {
		if (!e.ctrlKey && !e.shiftKey) {
			e.preventDefault();
			e.stopPropagation();
			handleOpen();
		}
	};

	if (!open) {
		return (
			<Button
				as={Link}
				to={`/accountings/${accounting.id}/entries/create?previous=${pathname}`}
				primary
				size="small"
				className={btnClassName}
				onClick={handleClickBuffer}
			>
				<Icon name="plus" /> {label}
			</Button>
		);
	}
	return (
		<DraggableModal
			id={id}
			key={id}
			title={label}
			open={open}
			handleClose={handleClose}
			handleOpen={handleOpen}
			size="large"
		>
			<EntryForm onSuccess={onSuccess} onCancel={handleClose} />
		</DraggableModal>
	);
};

export default ModalCreateEntry;
