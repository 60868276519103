import { POST } from "../constants/methods";

// keep spaces
export const formatFileName = string => string.replace(/\/+/g, '-').replace(/:+/g, '');

/**
 * @param {string[]} data
 * @param {string} extension
 * @param {string} separator
 * @param {boolean|undefined} replaceSpaces
 */
export const fileNameFromData = (
	data,
	extension,
	separator = '-',
	replaceSpaces = true
) => {
	const filename = data.reduce(
		(accu, str) => accu ? accu + separator + str : accu, 
		''
	) + `.${extension}`;

	if (replaceSpaces) return replaceSpaces(filename, separator);
	return filename;
};

/**
 * @param {Blob|File} blob
 * @param {string} filename
 * @return {Promise}
 */
export const autoDownload = (blob, filename) =>
	new Promise((resolve, reject) => {
		if (!(blob instanceof Blob) && !(blob instanceof File)) {
			console.error(
				new TypeError('blob parameter should be an instance of Blob or File.')
			);
			reject(
				new TypeError(
					"Le fichier n'a pas pu être téléchargé, suite à une erreur dans la réception des données."
				)
			);
		}
		filename = formatFileName(filename);
		if (window.navigator && window.navigator.msSaveBlob) {
			window.navigator.msSaveBlob(blob, filename);
		} else {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.style = 'display: none';
			a.href = url;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		}
		resolve(true);
	});

/**
 * @param {blob} blob
 */
export function openBlob(blob) {
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(blob);
	} else {
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style = 'display: none';
		a.href = url;
		a.target = '_blank';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		// Cannot revoke url else user could not download file
	}
}

export function redirectForm({ method = POST, action, inputs }) {
	const form = document.createElement('form');
	form.style = 'display: none';
	form.action = action;
	form.method = method;
	Object.entries(inputs).forEach(([name, value]) => {
		const input = document.createElement('input');
		input.type = 'hidden';
		input.name = name;
		input.value = value;
		form.appendChild(input);
	});
	document.body.appendChild(form);
	form.submit();
}
