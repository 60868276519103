// modules
import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
// components
import Page404 from '../pages/Page404';
import Page403 from '../pages/Page403';
import LoggingPage from '../pages/LoggingPage';
import DefaultPage from '../pages/DefaultPage';
import AccountingsPage from '../pages/AccountingsPage';
import useResetScroll from '../hooks/ui/useResetScroll';
const AccountingRouter = lazy(() => import('./AccountingRouter'));

function PageRouter() {
	useResetScroll();

	return (
		<Suspense fallback={<Loader active inline="centered" />}>
			<Routes>
				<Route
					path="/client-id/:clientId/token/:token/refresh-token/:refreshToken"
					element={<LoggingPage />}
				/>
				<Route
					path="/platform-accounting-id/:accountingExternalId/client-id/:clientId/token/:token/refresh-token/:refreshToken"
					element={<LoggingPage />}
				/>
				<Route path="/*" element={<LoggedRouter />} />
				<Route path="/404" element={<Page404 />} />
				<Route path="/403" element={<Page403 />} />
			</Routes>
		</Suspense>
	);
}

const LoggedRouter = () => {
	const user = useSelector(state => state.user);
	if (user.loading) {
		return <Loader active inline="centered" />;
	} else if (user.token && user.id) {
		return (
			<Routes>
				<Route path="/" element={<AccountingsPage />} />
				<Route
					path="/accountings/:idAccounting/*"
					element={<AccountingRouter />}
				/>
				{/* Redirection */}
				<Route path="/accountings" element={<Navigate replace to="/" />} />
				<Route path="*" element={<Navigate replace to="/404" />} />
			</Routes>
		);
	} else {
		return (
			<Routes>
				<Route path="/" element={<DefaultPage />} />
				{/* Redirection */}
				<Route path="*" element={<Navigate replace to="/" />} />
			</Routes>
		);
	}
};

export default PageRouter;
