import { useState } from 'react';

const useDropFile = (handleFiles, disabled = false) => {
	const [dragging, setDragging] = useState(false);

	const handleDrag = e => {
		if (disabled) return;
		e.preventDefault();
		e.stopPropagation();
		if (e.type === 'dragenter' || e.type === 'dragover') {
			e.dataTransfer.dropEffect = 'copy';
			setDragging(true);
		} else if (e.type === 'dragleave') {
			setDragging(false);
		}
	};
	const handleDrop = e => {
		if (disabled) return;
		e.preventDefault();
		e.stopPropagation();
		handleFiles(e.dataTransfer.files);
		setDragging(false);
	};

	return { dragging, handleDrag, handleDrop };
};

export default useDropFile;
