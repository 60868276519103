/**
 * Transform array of errors object to message object with fieldName keys
 * @param {array} errors
 * @return {object}
 */
export const buildFieldsMessage = errors =>
	errors.reduce(
		(errorsKeyed, { field, message }) => setParsedError(errorsKeyed, field, message),
		{}
	);

export function setParsedError(errors, name, value) {
	let haystack = errors;
	const attrStack = name.split('.');
	for (let i = 0; i < attrStack.length; i++) {
		const part = attrStack[i];
		const match = part.match(/^(\w+)\[(\d+)\]$/);
		if (match) {
			const [, arrayName, index] = match;
			if (!haystack[arrayName]) haystack[arrayName] = [];
			if (!haystack[arrayName][index]) haystack[arrayName][index] = {};

			if (i === attrStack.length - 1) {
				haystack[arrayName][index] = value;
			} else {
				haystack = haystack[arrayName][index];
			}
		} else {
			if (i === attrStack.length - 1) {
				haystack[part] = value;
			} else {
				if (!haystack[part]) haystack[part] = {};
				haystack = haystack[part];
			}
		}
	}

	return errors;
}
