export const parseBoolean = string => string === true || string === 'true';

export const strToPlrs = str =>
	str.endsWith('y') ? str.slice(0, -1) + 'ies' : str + 's';

export const stringEnum = (...args) =>
	args.reduce((acc, str) => (str ? acc + ' - ' + str : acc), '');

export const boolVal = val => (!val || val === '0' || val === 'false' ? false : true);

export const typeOf = value => {
	if (value === null) return 'null';
	if (Array.isArray(value)) return 'array';
	return typeof value;
};

export const replaceSpaces = (string, separator = '') =>
	string.replace(/\s+/g, separator);

export const capitalize = string => string[0].toUpperCase() + string.slice(1);

export const isVoid = value => value == null || value === '';

export const compare = (a, b) => a === b || (isVoid(a) && isVoid(b));

/**
 * [success, fail] = result
 * @param {array} array
 * @param {function} filterCallback
 * @returns {array}
 */
export const partition = (array, filterCallback) =>
	array.reduce(
		(result, el, i) => {
			result[filterCallback(el, i) ? 0 : 1].push(el);
			return result;
		},
		[[], []]
	);

/**
 *
 * @param {array} array
 * @param {function} callback
 * @returns {string}
 */
export const reduceToString = (array, callback = a => a) =>
	array.reduce((str, el) => str + (str ? ', ' : '') + callback(el), '');

export const arrayCompare = (a, b) => {
	if (!a && !b) return true;
	if (!Array.isArray(a) || !Array.isArray(b)) return false;
	return a.length === b.length && a.every((v, i) => v === b[i]);
};

/**
 * @param {object} object1
 * @param {object} object2
 * @returns {boolean} true = same | false = different
 */
export function objectCompare(object1, object2) {
	if (object1 == null && object2 == null) {
		return true;
	} else if (object1 == null || object2 == null) {
		return false;
	}
	if (Object.keys(object1).length !== Object.keys(object2).length) {
		return false;
	}
	for (let [key, value] of Object.entries(object1)) {
		// compare arrays as objects
		if (typeof value === 'object' && typeof object2[key] === 'object') {
			if (!objectCompare(value, object2[key])) {
				return false;
			}
		} else if (value !== object2[key]) {
			return false;
		}
	}
	return true;
}

/**
 * @param {object} data
 * @returns {FormData}
 */
export function buildFormData(data) {
	const formData = new FormData();
	Object.entries(data).map(([key, value]) => formData.append(key, value));
	return formData;
}
