import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { strToPlrs } from '../../functions';
import useUpload from '../fetch/actions/useUpload';

const useAttachment = ({ name = 'attachment', entityName, entity, onSuccess }) => {
	const [attachment, setAttachment] = useState(entity[name]);
	const accounting = useSelector(state => state.accounting);
	const entityNamePlur = useMemo(() => strToPlrs(entityName), [entityName]);
	const successRef = useRef(null);

	const { loading, handleSubmit } = useUpload({
		url: `accountings/${accounting.id}/${entityNamePlur}/${entity.id}/${name}`,
		callback:
			onSuccess &&
			(res => {
				const defferedResult = successRef.current;
				onSuccess({
					...defferedResult,
					[entityName]: {
						...defferedResult[entityName],
						[name]: res[name],
					},
				});
			}),
	});

	const handleAttachment = (e, { value }) => setAttachment(value);

	const preSuccess = value => {
		if (attachment) {
			successRef.current = value;
			handleSubmit(
				[attachment],
				`accountings/${accounting.id}/${entityNamePlur}/${successRef.current[entityName].id}/${name}`
			);
		} else {
			onSuccess(value);
		}
	};

	return { preSuccess, loading, attachment, handleAttachment };
};

export default useAttachment;
