// modules
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// custolm
import useFetch, { manageFetch, manageFetchError } from './useFetch';
import useLogout from '../session/useLogout';
import useLocalStorage from '../session/useLocalStorage';
import { setToken } from '../../reducers/userReducer';
import { addMessage, removeMessage } from '../../reducers/messagesReducer';
import { defaultObject } from '../../constants';
import { POST } from '../../constants/methods';
import { JSON_TYPE } from '../../constants/requestType';

const useLoggedFetch = ({
	handleResult,
	firstCallPriority = true,
	loadingIcon,
} = defaultObject) => {
	const dispatch = useDispatch();
	const logout = useLogout();
	const user = useSelector(state => state.user);
	const messages = useSelector(state => state.messages);
	const { getStoredItem, setItemToStorage } = useLocalStorage();
	const [orderRemoveMessage, setOrderRemoveMessage] = useState(false);

	// Called after useFetch.load catch an error
	// return a token
	const refreshToken = () => {
		let error = null;
		const storedData = getStoredItem(process.env.REACT_APP_LOCAL_KEY);
		// Use useFetch.manageFetch to load is own request
		// This try catch is an equivalent of the one in useFetch.load
		if (storedData) {
			addConnectionMessage();
			return manageFetch(process.env.REACT_APP_REFRESH_ENDPOINT, {
				method: POST,
				headers: { 'Content-Type': JSON_TYPE },
				body: JSON.stringify({ refresh_token: storedData.refreshToken }),
			})
				.then(refreshResult => {
					if (refreshResult.success) {
						dispatch(
							setToken(
								refreshResult.token,
								refreshResult.refreshToken || refreshResult.refresh_token
							)
						);
						setOrderRemoveMessage(true);
						// Reload the original request
						// Set this manageFetch result as useFetch.result
						// Reload errors are managed by manageError from manageFetch
						// manageError throwing is received by this try catch, so logout
						return refreshResult.token;
					}
					// Else throw the error to logout.
					throw refreshResult;
				})
				.catch(e => {
					const currentStoredData = getStoredItem(
						process.env.REACT_APP_LOCAL_KEY
					);
					if (
						currentStoredData &&
						currentStoredData.refreshToken === storedData.refreshToken
					) {
						logout();
						throw error;
					} else {
						return storedData.token;
					}
				});
		} else {
			error = { message: 'Session corrompue.' };
			logout();
		}

		setOrderRemoveMessage(true);
		throw error;
	};

	// Middleware to check if the error is a call to refresh the token
	// Next call the request to refresh the token and then recall the first request
	const manageError = (error, url, params = {}) => {
		if (!error) {
			error = {};
		} else if (typeof error !== 'object') {
			error = { message: error };
		}

		if (
			error.message === 'Invalid JWT Token' ||
			error.message === 'Expired JWT Token' ||
			error.message === "Une exception d'authentification s'est produite."
		) {
			return refreshToken()
				.then(token => {
					if (!params.headers) params.headers = {};
					params.headers.Authorization = `Bearer ${token}`;
					return manageFetch(url, params);
				})
				.catch(e => {
					if (!unmounted) handleResult(manageFetchError(e));
				});
		}

		if (error.message === 'JWT Token not found') return;

		error = manageFetchError(error);

		if (error && error.message) {
			dispatch(addMessage({ value: error.message, error: true }));
		}

		return error;
	};

	// Listen for result.token/refreshToken update from any request if user is logged
	const handleResultController = result => {
		Promise.resolve(result).then(result => {
			if (result?.success && result.token) {
				dispatch(
					setToken(result.token, result.refreshToken || result.refresh_token)
				);
			}
			handleResult(result);
		});
	};
	const [load, loading, unmounted] = useFetch({
		handleResult: handleResultController,
		firstCallPriority,
		manageError,
	});

	const loggedLoad = ({ token, url, ...request }) => {
		if (!request.headers) request.headers = {};
		if (!token) token = user.token;
		if (token) request.headers.Authorization = `Bearer ${token}`;
		request.url = process.env.REACT_APP_API_URL + url;
		load(request);
	};

	// Update Local Storage when token is updated
	useEffect(() => {
		if (user.token && user.id) {
			setItemToStorage(process.env.REACT_APP_LOCAL_KEY, user);
		}
	}, [user.token, user.refreshToken]);

	useEffect(() => {
		if (orderRemoveMessage) {
			const message = Object.entries(messages).find(
				([key, value]) => value.warning
			);
			if (message) {
				const [uuid] = message;
				dispatch(removeMessage(uuid));
			}
			setOrderRemoveMessage(false);
		}
	}, [orderRemoveMessage]);

	const addConnectionMessage = () => {
		dispatch(
			addMessage({
				value: 'Reconnexion',
				content: `<div>${loadingIcon} Reconnexion</div>`,
				warning: true,
			})
		);
	};

	return [loggedLoad, loading];
};

export default useLoggedFetch;
