// modules
import React, { useState, useEffect } from 'react';
import { Label, FormField, FormInput } from 'semantic-ui-react';
import { makeClassName } from '../functions/ui';

const classicSort = (value1, value2) => value1.localeCompare(value2);

/**
 * @param {string|undefined} value
 * @param {string|undefined} error
 * @param {function|undefined} onChange
 * @param {array|object} options
 */
const SelectField = ({
	name,
	value,
	error,
	onChange,
	options,
	className,
	order = true,
	orderByKey = false,
	disabledOption,
	placeholder = '',
	loading = false,
	sort = classicSort,
	children,
	...props
}) => {
	const buildOptions = () => {
		let entries = Object.entries(options);
		if (order) {
			entries = entries.sort(([key1, value1], [key2, value2]) =>
				orderByKey ? sort(key1, key2) : sort(value1, value2)
			);
		}
		return entries.map(([key, option]) => (
			<option
				key={key}
				value={key}
				disabled={disabledOption ? disabledOption(key, option) : false}
			>
				{option}
			</option>
		));
	};

	const [trueOptions, setOptions] = useState(buildOptions());

	useEffect(() => {
		setOptions(buildOptions());
	}, [options, order, orderByKey, disabledOption]);

	return (
		<div className={makeClassName('field', className)}>
			{children}
			{/* Make a div due to FormField error props problem */}
			{loading ? (
				<FormInput name={name} disabled loading {...props} />
			) : (
				<FormField
					name={name}
					control="select"
					value={value}
					error={error ? true : undefined}
					onChange={e => onChange(e, { name, value: e.target.value })}
					{...props}
				>
					{!!placeholder && (
						<option value="" hidden>
							{placeholder}
						</option>
					)}
					{trueOptions}
				</FormField>
			)}
			{!!error && (
				<Label prompt pointing className="above" role="alert">
					{error}
				</Label>
			)}
		</div>
	);
};

export default SelectField;
