import React from 'react';
import { Icon } from 'semantic-ui-react';
import useGoBack from '../hooks/useGoBack';
import useQuery from '../hooks/query/useQuery';

const GoBackButton = (/* { goBackRoute } */) => {
	const goBack = useGoBack();
	const { previous } = useQuery();
	return (
		<Icon
			link
			name="arrow circle left"
			title="Revenir en arrière"
			color="blue"
			onClick={e => goBack(previous)}
		/>
	);
};

export default GoBackButton;
