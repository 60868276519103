import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useLocalFetch from '../useLocalFetch';
import { strToPlrs } from '../../../functions';
import useFormErrors from '../../form/useFormErrors';
import { buildFieldsMessage } from '../../../functions/messages';
import { POST, PUT } from '../../../constants/methods';

function useCreate({ name, callback }) {
	const accounting = useSelector(state => state.accounting);
	const { errors, setErrors, handleError, handleInvalid, handleClearMsg } =
		useFormErrors();
	const namePlur = useMemo(() => strToPlrs(name), [name]);

	const [load, loading] = useLocalFetch({
		handleResult: result => {
			if (result) {
				if (result.success) {
					handleClearMsg();
					if (callback) callback(result);
				} else if (result.errors) {
					setErrors(buildFieldsMessage(result.errors));
				}
			}
		},
	});

	const handleSubmit = body => {
		load({
			url:
				`accountings/${accounting.id}/${namePlur}` +
				(body.id ? `/${body.id}` : ''),
			method: body.id ? PUT : POST,
			body,
		});
	};

	return {
		loading,
		errors,
		handleError,
		handleInvalid,
		handleClearMsg,
		handleSubmit,
	};
}

export default useCreate;
