import { useEffect, useRef } from 'react';

// key from keyboard constants
const useCatchKey = ({ key, escapeConditions }) => {
	const submitRef = useRef(null);

	useEffect(() => {
		document.addEventListener('keydown', onKeyDown);
		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	}, []);

	const onKeyDown = e => {
		if (e.key !== key) return;
		if (escapeConditions && escapeConditions(e.target)) return;
		e.preventDefault();
		e.stopPropagation();
		if (submitRef?.current) {
			submitRef.current.click();
		}
	};

	return submitRef;
};

// condition can be used with open value, undefined : addEvent oneTime, true : addEvent/reAddEvent, false : removeEvent
export const useElCatchKey = ({ key, id, callback, condition = true }) => {
	const onKeyDown = e => {
		if (e.key !== key) return;
		e.preventDefault();
		e.stopPropagation();
		callback();
	};

	useEffect(() => {
		const el = document.getElementById(id);
		if (el) {
			if (condition === false) {
				el.removeEventListener('keydown', onKeyDown);
			} else {
				el.addEventListener('keydown', onKeyDown);
			}
		}
		return () => {
			if (el) el.removeEventListener('keydown', onKeyDown);
		};
	}, [id, condition]);
};

export const useRefCatchKey = ({ key, callback, condition = true }) => {
	const ref = useRef();

	const onKeyDown = e => {
		if (e.key !== key) return;
		e.preventDefault();
		e.stopPropagation();
		callback(ref.current);
	};

	useEffect(() => {
		let el = ref.current;
		if (el.ref) el = el.ref.current;
		if (el) {
			if (condition === false) {
				el.removeEventListener('keydown', onKeyDown);
			} else {
				el.addEventListener('keydown', onKeyDown);
			}
		}
		return () => {
			if (el) el.removeEventListener('keydown', onKeyDown);
		};
	}, [ref.current, condition]);

	return ref;
};

export default useCatchKey;
