// modules
import { useEffect } from 'react';
import { useState } from 'react';

const useNextValue = ({ defaultValue, handleLoad, loading, result, handleResult }) => {
	const [value, setValue] = useState(defaultValue);
	const [valueInLoading, setValueInLoading] = useState(null);
	const [next, setNext] = useState(null);
	const [timeIsOk, setTimeIsOk] = useState(true);

	useEffect(() => {
		if (result) {
			if (next != null) {
				if (next !== value) {
					setValue(next);
				} else {
					setValue(null);
				}
				setNext(null);
			} else {
				// avoid infinite loop
				setValue(null);
				handleResult(result);
			}
		}
	}, [result]);

	const handleValue = nextValue => {
		if (loading) {
			if (nextValue !== next) {
				setNext(nextValue);
			}
		} else {
			if (nextValue !== value && nextValue !== next) {
				setValue(nextValue);
			}
		}
	};

	const timeoutOk = () => {
		setTimeIsOk(false);
		setTimeout(() => setTimeIsOk(true), 1000);
	};

	useEffect(() => {
		if (value != null && value !== valueInLoading && !next && timeIsOk && !loading) {
			handleLoad(value || undefined);
			setValueInLoading(value);
			// setValue(null); from useEffect result permit to avoid infinite loop
			timeoutOk();
		}
	}, [value, timeIsOk]);

	return [value, handleValue];
};

export default useNextValue;
